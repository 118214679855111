import React, { useEffect } from 'react'
import AppErrorResult from '../../components/common/AppErrorResult'
import { AppList, AppListItem } from '../../components/common/AppList'
import { AppLoading } from '../../components/common/AppLoading'
import AppSearchHeader from '../../components/common/AppSearchHeader'
import {
  useContentSearchContext,
  withContentSearch,
} from '../../context/ContentSearchContext'
import { useContentListQuery } from '../../hooks/coreApi/useContentListQuery'
import { useContentReadMutation } from '../../hooks/coreApi/useContentReadMutation'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { getDateFormat } from '../../utils/date'

const MusenList: React.FC = () => {
  useHeaderConfig({
    title: '防災無線',
  })

  const contentReadMutation = useContentReadMutation()

  const { query } = useContentSearchContext()
  const PAGE_SIZE = 20
  const params = {
    page: 1,
    pageSize: PAGE_SIZE,
    withFiles: true,
    search: query?.search,
    placeIds: query?.placeId ? [query.placeId] : undefined,
    startDate: query?.startDate,
    endDate: query?.endDate,
  }

  console.log(params)

  const contentQuery = useContentListQuery('musen', params)

  const { isLoading, isError, error, data, fetchNextPage, hasNextPage } =
    contentQuery

  useEffect(() => {
    const ids: number[] = []
    contentQuery.data?.pages.forEach((page) => {
      page.contents.forEach((content) => {
        ids.push(content.id)
      })
    })
    if (ids && ids.length > 0) {
      contentReadMutation.mutate({
        contentReadRequest: { contentIds: ids },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentQuery.data?.pages])

  if (isLoading) {
    return <AppLoading />
  }

  if (isError) {
    return <AppErrorResult error={error} />
  }

  return (
    <>
      <AppSearchHeader
        contentType="musen"
        isShowYear
        isShowArea
        defaultValues={{
          search: '',
          year: '',
          placeId: '',
          tag: '',
        }}
      />
      <AppList
        isMore={hasNextPage}
        onClickMore={() => {
          fetchNextPage()
        }}
      >
        {data.pages.map((page) =>
          page.contents.map((content) => (
            <AppListItem
              key={content.id}
              date={getDateFormat(content.releaseAt)}
              title={content.titleJa}
              description={content.bodyJa}
              isShowLike
              likeCount={content?.likeCount}
              isLiked={content?.isLiked}
              soundPath={content.files?.[0].cloudFrontUrl}
              contentId={content.id}
              isReaded={content?.isRead}
            />
          )),
        )}
      </AppList>
    </>
  )
}

export default withContentSearch(MusenList)
